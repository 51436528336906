import React, { useState } from "react";
import useStyles from "./styles";
import axios from "config/axios";

import CloudDownload from "@material-ui/icons/CloudDownload";
import ErrorIcon from "@material-ui/icons/Error";
import ReceiptIcon from "@material-ui/icons/Receipt";

import {
  Grid,
  Button,
  Paper,
  CircularProgress,
  Typography,
  Icon,
} from "@material-ui/core";

import download from "downloadjs";

import Snackbar from "components/Snackbar/Snackbar.js";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function Report() {
  const classes = useStyles();
  const [minDate, setMinDate] = useState(null),
    [maxDate, setMaxDate] = useState(null),
    [manualMinDate, setManualMinDate] = useState(null),
    [manualMaxDate, setManualMaxDate] = useState(null),
    [loading, setLoading] = useState(false),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState("");

  const generateReport = async () => {
    if (!minDate || !maxDate) {
      return setAlert("Preencha os dois campos");
    }
    if (new Date(minDate) > new Date(maxDate)) {
      return setAlert("Data mínima maior que a data máxima");
    }
    setLoading((oldState) => !oldState);
    axios
      .post(
        "/park/report/generate",
        {
          minDate,
          maxDate,
          timezone: new Date().getTimezoneOffset(),
        },
        {
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading((oldState) => !oldState);
        let { data } = val;
        download(data, `relatório-${new Date().getTime()}`, "application/pdf");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao gerar relatórío, tente novamente");
            break;
        }
      });
  };

  const generateManualReport = async () => {
    if (!manualMinDate || !manualMaxDate) {
      return setAlert("Preencha os dois campos");
    }
    if (new Date(manualMinDate) > new Date(manualMaxDate)) {
      return setAlert("Data mínima maior que a data máxima");
    }
    setLoading((oldState) => !oldState);
    axios
      .post(
        "/park/report/manual/openings/generate",
        {
          minDate: manualMinDate,
          maxDate: manualMaxDate,
          timezone: new Date().getTimezoneOffset(),
        },
        {
          responseType: "blob",
        }
      )
      .then((val) => {
        setLoading((oldState) => !oldState);
        let { data } = val;
        download(
          data,
          `relatório-abertura-manual-${new Date().getTime()}`,
          "application/pdf"
        );
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao gerar relatórío, tente novamente");
            break;
        }
      });
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert}
          open={alert ? true : false}
          closeNotification={() => setAlert("")}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={ErrorIcon}
          message={alertSuccess}
          open={alertSuccess ? true : false}
          closeNotification={() => setAlertSuccess("")}
          close
        />
        <Paper className={classes.paper}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item style={{ marginTop: 8 }}>
              <Icon>
                <ReceiptIcon />
              </Icon>
            </Grid>
            <Grid item>
              <Typography variant="h5">Gerar relatório</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <KeyboardDatePicker
                margin="normal"
                id="filterMinDate"
                label="Data mínima"
                required
                format="dd/MM/yyyy"
                value={minDate}
                invalidDateMessage="Data inválida"
                maxDate={new Date()}
                maxDateMessage="Não é possível ser uma data futura"
                onChange={(date) =>
                  setMinDate(() => {
                    try {
                      date = new Date(date.setHours(0));
                      date = new Date(date.setMinutes(1));
                      return date.getTime();
                    } catch (err) {
                      return date;
                    }
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "Mudar de vencimento",
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                margin="normal"
                id="filterMaxDate"
                label="Data máxima"
                required
                format="dd/MM/yyyy"
                value={maxDate}
                maxDate={new Date()}
                maxDateMessage="Não é possível ser uma data futura"
                invalidDateMessage="Data inválida"
                onChange={(date) =>
                  setMaxDate(() => {
                    try {
                      date = new Date(date.setHours(23));
                      date = new Date(date.setMinutes(59));
                      return date.getTime();
                    } catch (err) {
                      return date;
                    }
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "Mudar de vencimento",
                }}
              />
            </Grid>
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  onClick={generateReport}
                  endIcon={<CloudDownload />}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                >
                  Gerar relatório
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.paper} style={{ marginTop: "5%" }}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item style={{ marginTop: 8 }}>
              <Icon>
                <ReceiptIcon />
              </Icon>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                Gerar relatório de abertura manual
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <KeyboardDatePicker
                margin="normal"
                id="filterMinDate"
                label="Data mínima"
                required
                format="dd/MM/yyyy"
                value={manualMinDate}
                invalidDateMessage="Data inválida"
                maxDate={new Date()}
                maxDateMessage="Não é possível ser uma data futura"
                onChange={(date) =>
                  setManualMinDate(() => {
                    try {
                      date = new Date(date.setHours(0));
                      date = new Date(date.setMinutes(1));
                      return date.getTime();
                    } catch (err) {
                      return date;
                    }
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "Mudar de vencimento",
                }}
              />
            </Grid>
            <Grid item>
              <KeyboardDatePicker
                margin="normal"
                id="filterMaxDate"
                label="Data máxima"
                required
                format="dd/MM/yyyy"
                value={manualMaxDate}
                maxDate={new Date()}
                maxDateMessage="Não é possível ser uma data futura"
                invalidDateMessage="Data inválida"
                onChange={(date) =>
                  setManualMaxDate(() => {
                    try {
                      date = new Date(date.setHours(23));
                      date = new Date(date.setMinutes(59));
                      return date.getTime();
                    } catch (err) {
                      return date;
                    }
                  })
                }
                KeyboardButtonProps={{
                  "aria-label": "Mudar de vencimento",
                }}
              />
            </Grid>
            <Grid item>
              <div className={classes.wrapper}>
                <Button
                  onClick={generateManualReport}
                  endIcon={<CloudDownload />}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                >
                  Gerar relatório de abertura manual
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default Report;
