import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green, orange } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    dataRow: {
      margin: 10,
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridRowGap: 10,
    },
    divRight: {
      float: "right",
      justifyContent: "flex-end",
    },
    detailBtn: {},
    inputAmount: {
      width: "23vw",
    },
    inputFilterName: {
      width: 223.56,
      marginTop: 7,
    },
    selectors: {
      margin: 10,
      gridTemplateColumns: "10fr 50px 1fr",
      display: "grid",
    },
    pageSelectorContainer: {
      display: "flex",
      flexDirection: "row",
    },
    pageValue: {
      marginTop: "1.5vh",
      marginLeft: 10,
      marginRight: 10,
    },
    chipGreen: {
      borderColor: "green",
      borderWidth: 1.5,
      borderStyle: "solid",
    },
    chipYellow: {
      borderColor: "#f9f223",
      borderWidth: 1.5,
      borderStyle: "solid",
    },
    chipRed: {
      borderColor: "#dd0000",
      borderWidth: 1.5,
      borderStyle: "solid",
    },
    chipBlue: {
      borderColor: "#3333dd",
      borderWidth: 1.5,
      borderStyle: "solid",
    },
    chipOrange: {
      borderColor: orange["500"],
      borderWidth: 1.5,
      borderStyle: "solid",
    },
    filterPaper: {
      marginBottom: 20,
      padding: 10,
    },
    chipRelease: {
      justifyContent: "center",
    },
    chipReleaseConf: {
      justifyContent: "center",
      backgroundColor: "#7f6eb2",
      color: "white",
      "&:focus": {
        justifyContent: "center",
        backgroundColor: "#7f6eb2",
        color: "white",
      },
      "&:hover": {
        justifyContent: "center",
        backgroundColor: "#7f6ed9",
        color: "white",
      },
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
  })
);

export default useStyles;
