import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

export const selectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#8a6eb2" : isFocused && "#05b5a9",
      color: isSelected ? "white" : isFocused && "white",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
      },
    };
  },
};

const useStyles = makeStyles((theme) =>
  createStyles({
    gridText: {
      fontSize: 17,
    },
    greenChip: {
      backgroundColor: green[500],
      fontWeight: "bold",
      color: "white",
    },
    redChip: {
      backgroundColor: red[500],
      color: "white",
      fontWeight: "bold",
    },
  })
);

export default useStyles;
