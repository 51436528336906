/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
//import Dashboard from "@material-ui/icons/Dashboard";
//import Camera from "@material-ui/icons/Camera";
import Historic from "@material-ui/icons/LibraryBooks";
import CalendarToday from "@material-ui/icons/CalendarToday";
/* import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications"; */
//import Unarchive from "@material-ui/icons/Unarchive";
//import Language from "@material-ui/icons/Language";

// core components/views for Admin layout
//import DashboardPage from "views/Dashboard/Dashboard.js";
import HistoricPage from "views/Historic/Historic";
import MonthlyUser from "views/MonthlyUser/MonthlyUser";
import Vouchers from "views/Vouchers/Vouchers.js";
import Promotion from "views/Promotion/Promotion.js";
import Lane from "views/Lane/Lane.js";
import Report from "views/Report/Report.js";
/* import UserProfile from "views/UserProfile/UserProfile.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js"; */
//import Maps from "views/Maps/Maps.js";
//import NotificationsPage from "views/Notifications/Notifications.js";
//import Payment from "views/Payment/Payment.js";
//import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.js";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  /* {
    path: "/dashboard",
    name: "Status",
    //rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  }, */
  {
    path: "/historic",
    name: "Gerenciamento",
    //rtlName: "لوحة القيادة",
    icon: Historic,
    component: HistoricPage,
    layout: "/admin",
  },
  {
    path: "/monthlyuser",
    name: "Mensalista",
    //rtlName: "لوحة القيادة",
    icon: CalendarToday,
    component: MonthlyUser,
    layout: "/admin",
  },
  {
    path: "/vouchers",
    name: "Vouchers",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "confirmation_number",
    component: Vouchers,
    layout: "/admin",
  },
  {
    path: "/promotion",
    name: "Promoção",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "local_offer",
    component: Promotion,
    layout: "/admin",
  },
  {
    path: "/lane",
    name: "Pista",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "directions_car",
    component: Lane,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "Relatório",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "description",
    component: Report,
    layout: "/admin",
  },
  /*{
    path: "/card",
    name: "Pagamento",
    //rtlName: "ملف تعريفي للمستخدم",
    icon: "credit_card",
    component: Payment,
    layout: "/admin",
  }, 
   {
    path: "/table",
    name: "Table List",
    rtlName: "قائمة الجدول",
    icon: "content_paste",
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Typography",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Icons",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "خرائط",
    icon: LocationOn,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  }, */
];

export default dashboardRoutes;
