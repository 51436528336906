import React, { useState, useEffect, useCallback } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Divider,
  Paper,
  Chip,
  Input,
  InputLabel,
  FormControl,
  LinearProgress,
  Grid,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Select from "react-select";

import moment from "moment";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import InfoIcon from "@material-ui/icons/Info";
import Clear from "@material-ui/icons/Clear";
import Cached from "@material-ui/icons/Cached";
import Search from "@material-ui/icons/Search";
import LocalParking from "@material-ui/icons/LocalParking";
import TimeToLeave from "@material-ui/icons/TimeToLeave";

// custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import expStyles from "./styles.js";

import axios from "config/axios";

const useStyles = makeStyles(styles);

const selectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "#8a6eb2" : isFocused && "#05b5a9",
      color: isSelected ? "white" : isFocused && "white",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
      },
    };
  },
};

export default function Historic() {
  const classes = useStyles();
  const expClasses = expStyles();
  const [logs, setLogs] = useState([]),
    [selectedLog, setSelectedLog] = useState(""),
    [loading, setLoading] = useState(false),
    [amountPerPage, setAmountPerPage] = useState(10),
    [amount, setAmount] = useState(0),
    [page, setPage] = useState(1),
    [filterName, setFilterName] = useState(""),
    [filterInitDate, setFilterInitDate] = useState(null),
    [filterEndDate, setFilterEndDate] = useState(null),
    [filterType, setFilterType] = useState(""),
    [filterCellphone, setFilterCellphone] = useState(""),
    [showRelease, setShowRelease] = useState(false),
    [showBoards, setShowBoards] = useState(false),
    [boards, setBoards] = useState([]),
    [selectedBoard, setSelectedBoard] = useState(),
    [admPwd, setAdmPwd] = useState(""),
    [admBoardPwd, setAdmBoardPwd] = useState(""),
    [parkData, setParkData] = useState({}),
    [alert, setAlert] = useState({ alert: false, msg: "" }),
    [openLoading, setOpenLoading] = useState(false),
    [admBoardOpeningPwd, setAdmBoardOpeningPwd] = useState(""),
    [selectedOpeningBoard, setSelectedOpeningBoard] = useState(),
    [alertWarning, setAlertWarning] = useState({ alert: false, msg: "" }),
    [alertSuccess, setAlertSuccess] = useState({ alert: false, msg: "" });

  /* Para forçar update do state */
  const [, updateState] = React.useState();

  /* UseEffect que carrega os logs */
  const getData = async (pageParam, clear) => {
    if (filterInitDate && filterEndDate && filterInitDate === filterEndDate) {
      return setAlert({ alert: true, msg: "As datas não podem ser iguais" });
    }
    setLoading(true);
    let route = `/park/logs/${amountPerPage || 10}/${pageParam || page}/${
      filterName || 0
    }/${filterInitDate || 0}/${filterEndDate || 0}/${filterType || 0}/${
      filterCellphone.replace(/\D/g, "") || 0
    }`;
    if (clear) {
      route = `/park/logs/10/${pageParam || page}/0/0/0/0/0`;
    }
    return axios
      .get(route)
      .then(({ data }) => {
        setLoading(false);
        setLogs(data.data);
        setAmount(data.amount);
      })
      .catch((error) => {
        setLoading(false);
        let status = error?.response?.status;
        switch (status) {
          case 401:
            setAlert({ alert: true, msg: "Não autorizado" });
            break;
          default:
            setAlert({ alert: true, msg: "Problema ao carregar histórico" });
            break;
        }
        console.log(error);
      });
  };
  const getBoards = async () => {
    axios
      .get("/park/boards")
      .then(({ data }) => {
        let result = [];
        data.forEach((board) => {
          let type = "Entrada";
          switch (board.operationType) {
            case "in":
              type = "Cancela entrada";
              break;
            case "out":
              type = "Cancela saída";
              break;
            case "in_out":
              type = "Cancela mista";
              break;
            default:
              break;
          }
          result.push({ value: board._id, label: `${type} ${board.name}` });
        });
        setBoards(result);
      })
      .catch((error) => {
        console.log(error);
        setAlert({ alert: true, msg: "Problema ao carregar cancelas" });
      });
  };

  const getParkData = async () => {
    axios
      .get("/park")
      .then(({ data }) => {
        setParkData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getParkData();
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useState(() => {
    getBoards();
  }, [showBoards]);

  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  const diff_hours = (dt2, dt1, ceil, disableAbs) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    if (!disableAbs) {
      diff = Math.abs(diff);
    }
    if (ceil) {
      return Math.ceil(diff);
    } else {
      return diff;
    }
  };

  const calculatePrice = useCallback(
    (promotion) => {
      if (!selectedLog) {
        return "";
      }
      if (selectedLog.monthlyUser) {
        return "Mensalista";
      }
      if (selectedLog.out || selectedLog.release) {
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(selectedLog.finalPrice || 0);
      }

      if (selectedLog?.infoPrice?.is_toleance) {
        return "Dentro dos 15min";
      }
      if (promotion) {
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(
          selectedLog?.infoPrice?.value * (1 - selectedLog?.promotion?.value)
        );
      }
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(selectedLog?.infoPrice?.value || 0);
    },
    [selectedLog]
  );

  const procLabel = (item) => {
    if (item.payed && item.out) {
      return "Encerrado";
    }
    if (item.monthlyUser) {
      return "Mensalista";
    }
    if (item.payed && item.release && new Date() > new Date(item.release)) {
      return "Pagamento vencido";
    }
    if (item.payed) {
      return "Pago";
    }
    /* let hours = diff_hours(new Date(), new Date(item.in), false);
    if (hours <= 0.25) {
      return "Dentro dos 15min";
    } */
    return "Em andamento";
  };

  const showDetails = async (item) => {
    if (!item.finalPrice) {
      setLoading(true);
      let entrance = item.in;
      if (item.voucher) {
        entrance = moment(item.in).add(item.voucher.value, item.voucher.unity);
      }
      if (!parkData?.priceApi) {
        return "";
      }
      let infoPrice = await axios.post(parkData.priceApi, {
        inTime: entrance,
        outTime: new Date(),
      });
      console.log(infoPrice.data);
      item.infoPrice = infoPrice.data;
      setLoading(false);
    }
    setSelectedLog(item);
  };

  const previousPage = () => {
    if (page === 1) {
      return;
    }
    setPage((oldPage) => oldPage - 1);
    getData(page - 1);
  };
  const nextPage = () => {
    if (page * amountPerPage > amount) {
      return;
    }
    setPage((oldPage) => oldPage + 1);
    getData(page + 1);
  };

  const procDate = (item) => {
    if (window.innerWidth <= 900) {
      return moment(item?.out ? item.out : item.in).format("HH:mm");
    }
    return moment(item?.out ? item.out : item.in).format("DD/MM/YYYY - HH:mm");
  };

  const procChipClass = (item) => {
    if (item.out) {
      return;
    }
    if (item.monthlyUser) {
      return expClasses.chipBlue;
    }
    if (item.payed && item.release && new Date() > new Date(item.release)) {
      return expClasses.chipRed;
    }
    if (item.in && item.payed) {
      return expClasses.chipYellow;
    }
    /*  let hours = diff_hours(new Date(), new Date(item.in), false);
    if (hours <= 0.25) {
      return expClasses.chipOrange;
    } */
    if (item.in) {
      return expClasses.chipGreen;
    }
  };

  const procAmount = (e) => {
    let value = e.target.value;
    if (value > 500) {
      return setAlert({ alert: true, msg: "Valor muito alto" });
    }
    setPage(1);
    setAmountPerPage(e.target.value.replace(/\D/g, ""));
  };

  const procDisabled = useCallback(() => {
    if (
      filterName.length > 0 ||
      filterInitDate ||
      filterEndDate ||
      filterCellphone.length > 0
    ) {
      return true;
    }
    return false;
  }, [filterName, filterInitDate, filterEndDate, filterCellphone]);

  const clearFilter = async () => {
    setFilterEndDate(null);
    setFilterInitDate(null);
    setFilterName("");
    setFilterCellphone("");
    getData(null, true);
  };

  const releaseUser = async () => {
    setShowRelease(true);
    setLoading(true);
    axios
      .put("/park/release/user", {
        userId: selectedLog?.user?._id,
        parkUserPwd: admPwd,
      })
      .then(({ data }) => {
        setLoading(false);
        setAlert({ alert: false, msg: "" });
        let timeString;
        if (data.time === 15) {
          timeString = "15 minutos";
        }
        let time = (data.time / 60).toFixed(2);
        timeString = `${Math.floor(time) || 0} h e ${
          ((time - Math.floor(time)) * 60).toFixed(0) || 0
        } min`;
        setAlertSuccess({
          alert: true,
          msg: `Liberado com sucesso. Vencimento em ${timeString}`,
        });
        getData();
        setSelectedLog(null);
        setShowRelease(false);
        setAdmPwd("");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setAlertSuccess({ alert: false, msg: "" });
        switch (error?.response?.status) {
          case 404:
            setAlert({
              alert: true,
              msg: "Usuário não encontrado",
            });
            break;
          case 403:
            setAlert({
              alert: true,
              msg: "Usuário não está estacionado no local",
            });
            break;
          case 400:
            setAlert({
              alert: true,
              msg: "Senha inválida",
            });
            break;
          case 406:
            switch (error?.response?.data?.status) {
              case 1:
                setAlert({
                  alert: true,
                  msg: "Usuário já pago e dentro do tempo de saída",
                });
                setSelectedLog(null);
                getData();
                setShowRelease(false);
                setAdmPwd("");
                break;
              default:
                setAlert({
                  alert: true,
                  msg: "Senha inválida",
                });
                break;
            }
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao liberar pagamento do usuário",
            });
            break;
        }
      });
  };

  const changeBoardSelection = async (data) => {
    setSelectedBoard(data.value);
  };

  const changeBoardOpeningSelection = async (data) => {
    setSelectedOpeningBoard(data.value);
  };

  const openBoard = async () => {
    if (!selectedBoard) {
      return setAlert({ alert: true, msg: "Selecione uma opção" });
    }
    if (!admBoardPwd) {
      return setAlert({ alert: true, msg: "Insira a sua senha" });
    }
    setLoading(true);
    axios
      .post("/park/open/board", {
        boardId: selectedBoard,
        parkUserPwd: admBoardPwd,
        logId: selectedLog._id,
      })
      .then(() => {
        setLoading(false);
        setSelectedBoard(null);
        setShowBoards(false);
        setSelectedLog(null);
        setAlertSuccess({ alert: true, msg: "Cancela aberta com sucesso" });
        getData();
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 408:
            return setAlert({
              alert: true,
              msg: "Problema de comunicação com a cancela",
            });
          case 1:
            return setAlert({
              alert: true,
              msg: "Usuário não encontrado",
            });
          case 2:
            return setAlert({
              alert: true,
              msg: "Cancela não encontrada",
            });
          case 3:
            return setAlert({
              alert: true,
              msg: "Senha incorreta",
            });
          case 4:
            getData();
            setShowBoards(false);
            setSelectedLog(null);
            return setAlert({
              alert: true,
              msg: "Usuário já está fora do estacionamento",
            });
          case 5:
            return setAlert({
              alert: true,
              msg: "Veículo não detectado",
            });
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao abrir",
            });
        }
      });
  };

  const maskCellphone = async (e) => {
    let v = e.target.value;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    if (v.length > 11) {
      return;
    }
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    setFilterCellphone(v);
  };

  const procShowPayDate = () => {
    let out = new Date(selectedLog?.out),
      payDate = new Date(selectedLog?.payDate);
    if (
      out.getMinutes() === payDate.getMinutes() &&
      payDate.getHours() === out.getHours() &&
      payDate.getSeconds() === out.getSeconds() &&
      !selectedLog.finalPrice
    ) {
      return false;
    }
    return true;
  };

  const procVoucherType = () => {
    let unity;
    switch (selectedLog?.voucher?.unity) {
      case "minute":
        unity = "min.";
        break;
      case "hour":
        unity = "h.";
        break;
      default:
        unity = "min.";
        break;
    }
    return unity;
  };

  const simplyOpen = async () => {
    if (!selectedOpeningBoard) {
      return setAlert({ alert: true, msg: "Selecione uma opção" });
    }
    if (!admBoardOpeningPwd) {
      return setAlert({ alert: true, msg: "Insira a sua senha" });
    }
    setLoading(true);
    setOpenLoading(true);
    axios
      .post("/park/simply/open/board", {
        boardId: selectedOpeningBoard,
        parkUserPwd: admBoardOpeningPwd,
      })
      .then(() => {
        setLoading(false);
        setOpenLoading(false);
        setAlertSuccess({ alert: true, msg: "Cancela aberta com sucesso" });
      })
      .catch((error) => {
        setLoading(false);
        setOpenLoading(false);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 408:
            return setAlert({
              alert: true,
              msg: "Problema de comunicação com a cancela",
            });
          case 1:
            return setAlert({ alert: true, msg: "Usuário não encontrado" });
          case 2:
            return setAlert({ alert: true, msg: "Cancela não encontrada" });
          case 3:
            setAdmBoardOpeningPwd("");
            return setAlert({ alert: true, msg: "Senha incorreta" });
          case 5:
            return setAlert({ alert: true, msg: "Veículo não detectado" });
          default:
            return setAlert({ alert: true, msg: "Problema ao abrir" });
        }
      });
  };

  const renderOpenBoard = () => {
    return (
      <GridContainer>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <IconButton
            color="primary"
            className={classes.iconBtnBack}
            onClick={() => {
              setShowBoards(false);
              setAdmBoardPwd("");
              setSelectedBoard(null);
            }}
            disabled={loading}
          >
            <ArrowBack className={classes.iconBack} />
          </IconButton>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h4">{selectedLog?.user?.name}</Typography>
            <Typography variant="body2">{selectedLog?.user?.email}</Typography>
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={2}
              style={{ marginTop: 10 }}
            >
              <Grid item style={{ flex: 1, width: "100%" }}>
                <Select
                  options={boards}
                  onChange={changeBoardSelection}
                  //value={defaultOption}
                  placeholder="Selecionar cancela a ser aberta"
                  styles={selectStyles}
                />
              </Grid>
              <Grid item>
                <FormControl className={expClasses.inputFilterName}>
                  <InputLabel htmlFor="admPwd">Senha do admin</InputLabel>
                  <Input
                    id="admPwd"
                    type="password"
                    value={admBoardPwd}
                    onChange={(e) => setAdmBoardPwd(e.target.value)}
                  />
                </FormControl>
              </Grid>
              <Grid item>
                <div className={expClasses.wrapper}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => openBoard()}
                    disabled={loading}
                  >
                    Abrir
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={expClasses.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </GridItem>
      </GridContainer>
    );
  };

  const renderSelectedLog = () => {
    return (
      <GridContainer>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <IconButton
            color="primary"
            className={classes.iconBtnBack}
            onClick={() => {
              setSelectedLog(null);
              setShowRelease(false);
              setAdmPwd("");
            }}
          >
            <ArrowBack className={classes.iconBack} />
          </IconButton>
          <Card className={classes.root}>
            {loading && <LinearProgress />}
            <CardContent>
              <Typography variant="h4">{selectedLog?.user?.name}</Typography>
              <Typography variant="body2">
                {selectedLog?.user?.email}
              </Typography>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="body1">
                    Entrada:{" "}
                    {moment(selectedLog?.in).format("DD/MM/YYYY HH:mm")}{" "}
                    {selectedLog?.inBoard?.name && "- "}
                    {`Cancela ${selectedLog?.inBoard?.name}`}
                  </Typography>
                </Grid>
                {selectedLog?.payDate &&
                  selectedLog?.payed &&
                  !selectedLog.monthlyUser &&
                  procShowPayDate() && (
                    <Grid item>
                      <Typography variant="body1">
                        Pago:{" "}
                        {moment(selectedLog?.payDate).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                      </Typography>
                    </Grid>
                  )}
                {selectedLog?.out && (
                  <Grid item>
                    <Typography variant="body1">
                      Saída:{" "}
                      {moment(selectedLog?.out).format("DD/MM/YYYY HH:mm")}{" "}
                      {selectedLog?.outBoard?.name && "- "}
                      {`Cancela ${selectedLog?.outBoard?.name}`}
                    </Typography>
                  </Grid>
                )}
                {selectedLog?.voucher && (
                  <Grid item>
                    <Typography variant="body1">
                      Voucher:{" "}
                      {`${selectedLog?.voucher?.value} ${procVoucherType()}`}
                    </Typography>
                  </Grid>
                )}
                {selectedLog?.promotion && (
                  <Grid item>
                    <Typography variant="body1">
                      Promoção de:{" "}
                      {`${(selectedLog?.promotion?.value * 100).toFixed(0)}% `}
                    </Typography>
                  </Grid>
                )}
                {selectedLog?.payed ? (
                  <Grid item style={{ marginTop: "2rem" }}>
                    {selectedLog?.out ? (
                      <Typography variant="h5">
                        Encerrado, valor pago: {calculatePrice()}
                      </Typography>
                    ) : (
                      <Typography variant="h5">
                        Estacionamento pago, valor pago: {calculatePrice()}
                      </Typography>
                    )}
                  </Grid>
                ) : selectedLog?.infoPrice?.is_toleance ? (
                  <Grid item style={{ marginTop: "2rem" }}>
                    <Typography variant="h5">
                      Dentro do período de tolerância. Acaba em:{" "}
                      {selectedLog?.infoPrice?.time} min.
                    </Typography>
                  </Grid>
                ) : (
                  <Grid item style={{ marginTop: "2rem" }}>
                    <Typography variant="h5">
                      Em andamento: {calculatePrice()}{" "}
                      {selectedLog?.voucher && ` - Com voucher`}
                      {selectedLog?.promotion &&
                        ` - Com promoção: ${calculatePrice(true)}`}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions
              style={{ textAlign: "center", justifyContent: "center" }}
            >
              {showRelease && (
                <FormControl className={expClasses.inputFilterName}>
                  <InputLabel htmlFor="admPwd2">Senha do admin</InputLabel>
                  <Input
                    id="admPwd2"
                    type="password"
                    value={admPwd}
                    onChange={(e) => setAdmPwd(e.target.value)}
                  />
                </FormControl>
              )}
              {(!selectedLog?.infoPrice?.is_toleance &&
                !selectedLog.out &&
                !selectedLog.payed &&
                !selectedLog.monthlyUser) ||
              (selectedLog.payed &&
                selectedLog.release &&
                new Date() > new Date(selectedLog.release) &&
                !selectedLog.out &&
                !selectedLog.monthlyUser) ? (
                <Chip
                  label={showRelease ? "Pagamento" : "Liberar pagamento"}
                  icon={
                    <LocalParking
                      style={showRelease ? { color: "white" } : {}}
                    />
                  }
                  className={
                    showRelease
                      ? expClasses.chipReleaseConf
                      : expClasses.chipRelease
                  }
                  onClick={() => {
                    if (showRelease) {
                      return releaseUser();
                    }
                    setShowRelease(true);
                  }}
                />
              ) : (
                <></>
              )}
              {!selectedLog.out && (
                <Chip
                  label={"Abrir cancela"}
                  icon={<TimeToLeave />}
                  onClick={() => {
                    setShowBoards(true);
                    if (
                      !selectedLog.payed &&
                      !selectedLog.monthlyUser &&
                      diff_hours(new Date(), new Date(selectedLog.in)) > 0.25
                    ) {
                      setAlertWarning({
                        alert: true,
                        msg: "Atenção!! O usuário não está pago.",
                      });
                    }
                  }}
                />
              )}
            </CardActions>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() => setAlert({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess.msg}
        open={alertSuccess.alert}
        closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="tc"
        color="warning"
        icon={InfoIcon}
        message={alertWarning.msg}
        open={alertWarning.alert}
        closeNotification={() => setAlertWarning({ alert: false, msg: "" })}
        close
      />
      {selectedLog ? (
        showBoards ? (
          renderOpenBoard()
        ) : (
          renderSelectedLog()
        )
      ) : (
        <>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Paper style={{ padding: 16, marginBottom: 20 }}>
              <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Abrir cancela
              </Typography>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
                style={{ marginTop: 10 }}
              >
                <Grid item style={{ flex: 1, width: "100%" }}>
                  <Select
                    options={boards}
                    onChange={changeBoardOpeningSelection}
                    //value={defaultOption}
                    placeholder="Selecione cancela a ser aberta"
                    styles={selectStyles}
                  />
                </Grid>
                <Grid item>
                  <FormControl className={classes.inputFilterName}>
                    <InputLabel htmlFor="yourPwd">Sua senha</InputLabel>
                    <Input
                      id="yourPwd"
                      type="password"
                      value={admBoardOpeningPwd}
                      onChange={(e) => setAdmBoardOpeningPwd(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <div className={classes.wrapper}>
                    <Button
                      onClick={simplyOpen}
                      variant="contained"
                      color="secondary"
                      disabled={loading || openLoading}
                    >
                      Abrir
                    </Button>
                    {openLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={expClasses.filterPaper}>
              <Typography variant="h4">Filtros</Typography>
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item>
                  <FormControl className={expClasses.inputFilterName}>
                    <InputLabel htmlFor="filterName">
                      Nome do cliente
                    </InputLabel>
                    <Input
                      id="filterName"
                      type="text"
                      value={filterName}
                      onChange={(e) => setFilterName(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl className={expClasses.inputFilterName}>
                    <InputLabel htmlFor="filterCellphone">
                      Telefone do cliente
                    </InputLabel>
                    <Input
                      id="filterCellphone"
                      type="text"
                      value={filterCellphone}
                      onChange={maskCellphone}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    margin="normal"
                    id="filterInitDate"
                    label="Data mínima"
                    format="dd/MM/yyyy"
                    value={filterInitDate}
                    invalidDateMessage="Data inválida"
                    onChange={(date) =>
                      setFilterInitDate(() => {
                        try {
                          date = new Date(date.setHours(0));
                          date = new Date(date.setMinutes(1));
                          return date.getTime();
                        } catch (err) {
                          return date;
                        }
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "Mudar data mínima",
                    }}
                  />
                </Grid>
                <Grid item>
                  <KeyboardDatePicker
                    margin="normal"
                    id="filterEndDate"
                    label="Data máxima"
                    format="dd/MM/yyyy"
                    value={filterEndDate}
                    invalidDateMessage="Data inválida"
                    onChange={(date) =>
                      setFilterEndDate(() => {
                        try {
                          date = new Date(date.setHours(23));
                          date = new Date(date.setMinutes(59));
                          return date.getTime();
                        } catch (err) {
                          return date;
                        }
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "Mudar data máxima",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ textAlign: "center", marginTop: 10 }}
                >
                  <Button
                    onClick={clearFilter}
                    startIcon={<Clear />}
                    variant="contained"
                    color="secondary"
                  >
                    Limpar
                  </Button>
                  <Button
                    onClick={() => getData()}
                    startIcon={<Search />}
                    variant="contained"
                    color="secondary"
                    style={{ marginLeft: 10 }}
                  >
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
            <Paper>
              {loading && <LinearProgress />}
              <GridContainer>
                {logs.length !== 0 && (
                  <>
                    <GridItem xs={12}>
                      <div className={expClasses.selectors}>
                        <div></div>
                        {/* <FormControl className={expClasses.inputAmount}>
                          <InputLabel htmlFor="amount">Qtde.</InputLabel>
                          <Input
                            id="amount"
                            type="text"
                            value={amountPerPage}
                            onChange={procAmount}
                            disabled={procDisabled() ? true : false}
                          />
                        </FormControl> */}
                        <IconButton
                          onClick={() => {
                            getData();
                            getBoards();
                          }}
                        >
                          <Cached />
                        </IconButton>
                        <div className={expClasses.pageSelectorContainer}>
                          <IconButton
                            onClick={previousPage}
                            disabled={procDisabled() ? true : false}
                          >
                            <ArrowBack />
                          </IconButton>
                          <Typography
                            variant="body1"
                            className={expClasses.pageValue}
                          >
                            {page}
                          </Typography>
                          <IconButton
                            onClick={nextPage}
                            disabled={procDisabled() ? true : false}
                          >
                            <ArrowForward />
                          </IconButton>
                        </div>
                      </div>
                      <Divider component="hr" />
                    </GridItem>
                    <GridItem xs={12}>
                      <div className={expClasses.dataRow}>
                        <Typography
                          variant={window.innerWidth <= 900 ? "body2" : "body1"}
                        >
                          Nome do cliente
                        </Typography>
                        <Typography
                          variant={window.innerWidth <= 900 ? "body2" : "body1"}
                        >
                          Última atualização
                        </Typography>
                        <Typography
                          variant={window.innerWidth <= 900 ? "body2" : "body1"}
                          align="center"
                        >
                          Status
                        </Typography>
                      </div>
                      <Divider component="hr" />
                    </GridItem>
                  </>
                )}
                {logs.length === 0 && (
                  <GridItem xs={12} style={{ marginTop: 20 }}>
                    <Card className={classes.root}>
                      <CardContent>
                        <Typography variant="body1" align="center">
                          Sem dados
                        </Typography>
                      </CardContent>
                    </Card>
                  </GridItem>
                )}
                {logs.map(
                  (item, index) =>
                    item.user && (
                      <GridItem xs={12} key={index}>
                        <div className={expClasses.dataRow}>
                          <Typography
                            variant={
                              window.innerWidth <= 900 ? "body2" : "body1"
                            }
                          >
                            {/*  {index + 1}  - */}
                            {item?.user?.name}
                          </Typography>
                          <Typography
                            variant={
                              window.innerWidth <= 900 ? "body2" : "body1"
                            }
                          >
                            {procDate(item)}
                          </Typography>
                          <Chip
                            label={procLabel(item)}
                            icon={<InfoIcon />}
                            className={procChipClass(item)}
                            onClick={() => showDetails(item)}
                          />
                        </div>
                        <Divider component="hr" />
                      </GridItem>
                    )
                )}
              </GridContainer>
            </Paper>
            {filterName.length === 0 && (
              <GridItem xs={12} style={{ marginTop: 10 }}>
                <Typography align="center">
                  Total de {amount} registros
                </Typography>
              </GridItem>
            )}
          </MuiPickersUtilsProvider>
        </>
      )}
    </div>
  );
}
