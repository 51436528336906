import React, { useState, useEffect } from "react";
import axios from "config/axios";
import moment from "moment";

/* Material UI icons */
import ErrorIcon from "@material-ui/icons/Error";
import LocalOfferOutlined from "@material-ui/icons/LocalOfferOutlined";

//Material UI Core
import {
  Typography,
  Button,
  Grid,
  Paper,
  Icon,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";

import useStyles from "./styles";

function Promotion() {
  const [value, setValue] = useState(""),
    [range, setRange] = useState(""),
    [loading, setLoading] = useState(false),
    [confirmPromotion, setConfirmPromotion] = useState(false),
    [maxDate, setMaxDate] = useState(null),
    [alert, setAlert] = useState(""),
    [promotions, setPromotions] = useState([]),
    [alertSuccess, setAlertSuccess] = useState("");
  const classes = useStyles();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  let isMobile = window.innerWidth <= 900;

  const generatePromotion = async () => {
    setLoading((oldState) => !oldState);
    setConfirmPromotion(false);
    axios
      .post("/park/promotion", {
        maxDate,
        value: (value / 100).toFixed(2),
        range,
        timezone: new Date().getTimezoneOffset(),
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlertSuccess("Promoção gerada com sucesso");
        getPromotions();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao gerar promoção");
            break;
        }
      });
  };

  const getPromotions = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/park/promotion")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setPromotions(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao mostrar promoções");
            break;
        }
      });
  };

  useEffect(() => {
    getPromotions();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  //UseEffects para limpar automáticamente os alertas opostos
  useEffect(() => {
    setAlertSuccess("");
  }, [alert]);

  useEffect(() => {
    setAlert("");
  }, [alertSuccess]);

  const procValue = async (e) => {
    let { value } = e.currentTarget;
    if (value.length > 3) {
      return;
    }
    if (value > 100) {
      return;
    }
    if (/\D/.test(value)) {
      return;
    }
    setValue(e.currentTarget.value);
  };
  const procRange = async (e) => {
    let { value } = e.currentTarget;
    if (value.length > 2) {
      return;
    }
    if (value > 30) {
      return;
    }
    if (/\D/.test(value)) {
      return;
    }
    setRange(e.currentTarget.value);
  };

  const procGenerateClick = async () => {
    if (!value || !maxDate || !range) {
      return setAlert(`Preencha todos os campos`);
    }
    setConfirmPromotion(true);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ConfirmDialog
          openDialog={confirmPromotion}
          title={`Confirmar o envio de promoção?`}
          body1="Ao enviar todos os usuário serão notificados"
          handleYes={generatePromotion}
          handleClose={() => setConfirmPromotion(false)}
        />
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert}
          open={alert ? true : false}
          closeNotification={() => setAlert("")}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={ErrorIcon}
          message={alertSuccess}
          open={alertSuccess ? true : false}
          closeNotification={() => setAlertSuccess("")}
          close
        />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item style={{ marginTop: 8 }}>
                  <Icon>
                    <LocalOfferOutlined />
                  </Icon>
                </Grid>
                <Grid item>
                  <Typography variant="h5">Gerar promoção</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={isMobile ? 12 : 4}>
                  <TextField
                    fullWidth
                    label="Valor (porcentagem)"
                    value={value}
                    onChange={procValue}
                    required
                    style={{ marginTop: 8 }}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <TextField
                    fullWidth
                    label="Dias para amostra"
                    value={range}
                    onChange={procRange}
                    required
                    style={{ marginTop: 8 }}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    id="filterEndDate"
                    label="Data de vencimento"
                    required
                    format="dd/MM/yyyy"
                    value={maxDate}
                    invalidDateMessage="Data inválida"
                    minDate={new Date()}
                    minDateMessage="Data precisa ser futura"
                    onChange={(date) =>
                      setMaxDate(() => {
                        try {
                          date = new Date(date.setHours(23));
                          date = new Date(date.setMinutes(59));
                          return date.getTime();
                        } catch (err) {
                          return date;
                        }
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "Mudar de vencimento",
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => procGenerateClick()}
                      disabled={loading}
                      size="large"
                    >
                      Gerar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            {promotions.length === 0 ? (
              <>
                <Typography
                  variant="body1"
                  style={{ fontSize: 20 }}
                  align="center"
                >
                  Sem promoções
                </Typography>
              </>
            ) : (
              !isMobile && (
                <div className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="body1" align="center">
                        #
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Valor
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Usuários totais ► usuários restantes
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Data de vencimento
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Data de criação
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )
            )}
          </Grid>
          <Grid item xs={12}>
            {promotions.map((e, i) => (
              <Grid item key={i} xs={12} style={{ marginTop: 10 }}>
                <Paper className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify={isMobile ? "center" : "space-between"}
                    alignItems="center"
                    spacing={isMobile ? 4 : 2}
                  >
                    {!isMobile && (
                      <Grid item>
                        <Typography variant="body1" align="center">
                          {i + 1}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Typography variant="body1" align="center">
                        {(e.value * 100).toFixed(0)}%
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        {e.usersAffected} ► {e.users.length}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        {moment(e.maxDate).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        {moment(e.createdAt).format("DD/MM/YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default Promotion;
