import React, { useState, useEffect } from "react";
import axios from "config/axios";
import Select from "react-select";
import moment from "moment";
import download from "downloadjs";

/* Material UI icons */
import ErrorIcon from "@material-ui/icons/Error";
import GiftCard from "@material-ui/icons/CardGiftcard";
import CloudDownload from "@material-ui/icons/CloudDownload";

//Material UI Core
import {
  Typography,
  Button,
  Grid,
  Paper,
  Icon,
  TextField,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles, { selectStyles } from "./styles";

function Payment() {
  const [amount, setAmount] = useState(""),
    [value, setValue] = useState(""),
    [loading, setLoading] = useState(false),
    [timeOptions] = useState([
      { value: "minute", label: "minutos" },
      { value: "hour", label: "horas" },
    ]),
    [selectedTime, setSelectedTime] = useState(),
    [vouchers, setVouchers] = useState([]),
    [maxDate, setMaxDate] = useState(null),
    [description, setDescription] = useState(""),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState("");
  const classes = useStyles();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  let isMobile = window.innerWidth <= 900;

  const generateVouchers = async () => {
    if (!value || !amount || !selectedTime || !maxDate) {
      return setAlert("Preencha todos os campos com *");
    }
    //Verifica a data
    if (
      new Date(maxDate).getDate() < new Date().getDate() &&
      new Date(maxDate).getMonth() < new Date().getMonth()
    ) {
      return setAlert("Data de vencimento inválida");
    }
    setLoading((oldState) => !oldState);
    axios
      .post("/park/vouchers", {
        amount,
        value,
        type: selectedTime,
        description,
        maxDate,
        timezone: new Date().getTimezoneOffset(),
      })
      .then(({ data }) => {
        console.log(data);
        setAlertSuccess("Vouchers criados com sucesso");
        getBatchs();
        setLoading((oldState) => !oldState);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao gerar códigos, tente novamente");
            break;
        }
      });
  };

  const getBatchs = async () => {
    axios
      .get("/park/vouchers/batchs")
      .then(({ data }) => {
        setVouchers(data);
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao carregar vouchers");
            break;
        }
      });
  };

  const downloadVouchers = async (data) => {
    axios
      .get(`/park/vouchers/download/${data.uuid}`, {
        responseType: "blob",
      })
      .then((val) => {
        let { data, headers } = val;
        let contentType = headers["content-type"],
          split = contentType.split(";");
        download(data, split[1].substring(11, 32), split[0]);
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao baixar arquivo");
            break;
        }
      });
  };

  useEffect(() => {
    getBatchs();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  //UseEffects para limpar automáticamente os alertas opostos
  useEffect(() => {
    setAlertSuccess("");
  }, [alert]);

  useEffect(() => {
    setAlert("");
  }, [alertSuccess]);

  const procAmount = async (e) => {
    let { value } = e.currentTarget;
    if (value.length > 3) {
      return;
    }
    if (/\D/.test(value)) {
      return;
    }
    setAmount(e.currentTarget.value);
  };

  const procValue = async (e) => {
    let { value } = e.currentTarget;
    if (value.length > 3) {
      return;
    }
    if (/\D/.test(value)) {
      return;
    }
    setValue(e.currentTarget.value);
  };

  const procDescription = async (e) => {
    let { value } = e.currentTarget;
    if (value.length > 15) {
      return;
    }
    setDescription(value);
  };

  return (
    <div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert}
          open={alert ? true : false}
          closeNotification={() => setAlert("")}
          close
        />
        <Snackbar
          place="tc"
          color="success"
          icon={ErrorIcon}
          message={alertSuccess}
          open={alertSuccess ? true : false}
          closeNotification={() => setAlertSuccess("")}
          close
        />
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={3}
              >
                <Grid item style={{ marginTop: 8 }}>
                  <Icon>
                    <GiftCard />
                  </Icon>
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    Gerar vouchers de desconto
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={isMobile ? 12 : 4}>
                  <TextField
                    fullWidth
                    label="Descrição"
                    value={description}
                    onChange={procDescription}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <TextField
                    fullWidth
                    label="Qtd. de vouchers"
                    value={amount}
                    onChange={procAmount}
                    required
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <TextField
                    fullWidth
                    label="Qtd. de tempo"
                    value={value}
                    onChange={procValue}
                    required
                  />
                </Grid>
                <Grid item style={{ flex: 1, width: "50%", marginTop: 10 }}>
                  <Select
                    options={timeOptions}
                    onChange={(e) => setSelectedTime(e.value)}
                    //value={defaultOption}
                    placeholder="Unidade de tempo"
                    styles={selectStyles}
                  />
                </Grid>
                <Grid item xs={isMobile ? 12 : 4}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="filterEndDate"
                    label="Data de vencimento"
                    required
                    format="dd/MM/yyyy"
                    value={maxDate}
                    invalidDateMessage="Data inválida"
                    minDate={new Date()}
                    minDateMessage="Data precisa ser futura"
                    onChange={(date) =>
                      setMaxDate(() => {
                        try {
                          date = new Date(date.setHours(23));
                          date = new Date(date.setMinutes(59));
                          return date.getTime();
                        } catch (err) {
                          return date;
                        }
                      })
                    }
                    KeyboardButtonProps={{
                      "aria-label": "Mudar de vencimento",
                    }}
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={generateVouchers}
                      disabled={loading}
                      size="large"
                    >
                      Gerar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            {vouchers.length === 0 ? (
              <>
                <Typography
                  variant="body1"
                  style={{ fontSize: 20 }}
                  align="center"
                >
                  Sem vouchers
                </Typography>
              </>
            ) : (
              !isMobile && (
                <div className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="body1" align="center">
                        #
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Desc.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Qtde.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Data de venc.
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" align="center">
                        Baixar
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )
            )}
          </Grid>
          {vouchers.map((e, i) => (
            <Grid item key={i} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  container
                  direction="row"
                  justify={isMobile ? "center" : "space-between"}
                  alignItems="center"
                  spacing={2}
                >
                  {!isMobile && (
                    <Grid item>
                      <Typography variant="body1" align="center">
                        {i + 1}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={isMobile ? 12 : false}>
                    <Typography variant="body1" align="center">
                      {e.description ? e.description : isMobile ? "" : "-"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      {e.amount} vouchers
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" align="center">
                      {e?.maxDate
                        ? moment(e.maxDate).format("DD/MM/YYYY")
                        : "-"}
                    </Typography>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }}>
                    <IconButton onClick={() => downloadVouchers(e)}>
                      <CloudDownload />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default Payment;
