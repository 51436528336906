import React, { useState, useEffect, useCallback, useMemo } from "react";
import axios from "config/axios";

/* Material UI icons */
import ErrorIcon from "@material-ui/icons/Error";
import Refresh from "@material-ui/icons/Refresh";

//Material UI Core
import {
  Typography,
  Grid,
  Paper,
  LinearProgress,
  Divider,
  IconButton,
  TextField,
  Button,
} from "@material-ui/core";

import { Line, Pie } from "react-chartjs-2";

import moment from "moment";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles from "./styles";

function Payment() {
  const [loading, setLoading] = useState(false),
    [data, setData] = useState(),
    [lineChartData, setLineChartData] = useState({}),
    [pieChartData, setPieChartData] = useState({}),
    [parkData, setParkData] = useState(),
    [parkSlots, setParkSlots] = useState("0"),
    [alert, setAlert] = useState(""),
    [carsOffset, setCarsOffset] = useState("0"),
    [alertSuccess, setAlertSuccess] = useState("");
  const classes = useStyles();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  let isMobile = window.innerWidth <= 900;

  const procChartData = async (apiData) => {
    let barData = [],
      backgroundColor = ["rgba(127, 110, 178, 1)"],
      labels = [];
    for (let i = 0; i < 25; i++) {
      barData.push(0);
      backgroundColor.push("rgba(5, 181, 169, 1)");
      labels.push(`${i}:00`);
    }
    for (let e of apiData) {
      let hour = new Date(e.createdAt).getHours(),
        old = barData[hour];
      barData[hour] = old + 1;
    }
    let sum = 0;
    for (let item of barData) {
      sum += item;
    }
    setLineChartData({
      labels,
      datasets: [
        {
          label: "Número de entradas",
          data: barData,
          backgroundColor,
          borderWidth: 4,
        },
      ],
      sum,
    });
  };

  const getData = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axios
      .get(`/park/lanes/${new Date().getTimezoneOffset()}`)
      .then(async ({ data }) => {
        setLoading((oldState) => !oldState);
        setData(data);
        procChartData(data.chartData);
        //console.log(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao carregar dados");
            break;
        }
      });
  }, []);

  const updateData = async () => {
    if (!parkSlots || parkSlots === "0") {
      return setAlert("Preencha o campo com a quantidade de vagas");
    }
    axios
      .put("/park/data", {
        slots: parkSlots,
        carsOffset: !carsOffset ? 0 : carsOffset,
      })
      .then(async () => {
        getData();
        getParkData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao atualizar dados");
            break;
        }
      });
  };

  const getParkData = useCallback(async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/park")
      .then(async ({ data }) => {
        setLoading((oldState) => !oldState);
        setParkData(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            setAlert("Problema ao carregar dados do shopping");
            break;
        }
      });
  }, []);

  useEffect(() => {
    setPieChartData({
      labels: ["Vagas livres", "Vagas ocupadas"],
      datasets: [
        {
          data: [
            parkData?.slots -
              (data?.dataCount?.sensorPort1?.inData +
                parkData?.carsOffset -
                data?.dataCount?.sensorPort1?.outData),
            data?.dataCount?.sensorPort1?.inData -
              data?.dataCount?.sensorPort1?.outData +
              parkData?.carsOffset,
          ],
          backgroundColor: [
            "rgba(5, 181, 169, 1)",
            "rgba(127, 110, 178, 1)",
            "rgba(127, 110, 178, 1)",
            "rgba(127, 110, 178, 1)",
            "rgba(127, 110, 178, 1)",
            "rgba(127, 110, 178, 1)",
            "rgba(127, 110, 178, 1)",
          ],
        },
      ],
      borderWidth: 0,
    });
  }, [parkData, data]);

  useEffect(() => {
    setCarsOffset(parkData?.carsOffset || "0");
    setParkSlots(parkData?.slots || "0");
  }, [parkData]);

  useEffect(() => {
    //Recarrega de 10 em 10 min
    getData();
    getParkData();
  }, [getData, getParkData]);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  //UseEffects para limpar automáticamente os alertas opostos
  useEffect(() => {
    setAlertSuccess("");
  }, [alert]);

  useEffect(() => {
    setAlert("");
  }, [alertSuccess]);

  const procCarSlots = (e) => {
    let value = e.target.value;
    if (/\D/.test(value)) {
      return;
    }
    setParkSlots(value);
  };
  const procCarOffset = (e) => {
    let value = e.target.value;
    //eslint-disable-next-line
    if (/[a-zA-Z!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?˜~çÇ¨]/.test(value)) {
      return;
    }
    setCarsOffset(value);
  };

  const procMeanActivity = useMemo(() => {
    let value = 0;
    if (!data?.meanActivity) {
      return 0;
    }
    for (let e of data?.meanActivity) {
      if (e._id) {
        value = e.countGt;
      }
    }
    console.log(value);
    return Math.round(value);
  }, [data]);

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={ErrorIcon}
        message={alertSuccess}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      {loading && <LinearProgress />}
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography variant="h4">
            Controle de pista diário{" "}
            <IconButton
              disabled={loading}
              onClick={() => {
                getData();
                getParkData();
              }}
            >
              <Refresh />
            </IconButton>
          </Typography>
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 10 }}>
          <TextField
            fullWidth
            value={carsOffset}
            onChange={procCarOffset}
            label="Offset de contagem"
          />
        </Grid>
        <Grid item xs={3} style={{ marginBottom: 10 }}>
          <TextField
            fullWidth
            value={parkSlots}
            onChange={procCarSlots}
            label="Vagas"
          />
        </Grid>
        <Grid item style={{ marginBottom: 10 }}>
          <Button
            disabled={loading}
            onClick={() => {
              updateData();
            }}
            variant="contained"
            color="secondary"
          >
            Atualizar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            {parkData?.name} - {moment().utc().format("DD/MM/YYYY HH:mm")} UTC
          </Typography>
          <Paper className={classes.paper}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Estatística últimos 30 dias Park Lockyt
            </Typography>
            <Divider style={{ marginBottom: 10 }} />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1" align="center">
                  Média de carros por dia:{" "}
                  <b>{Math.round(data?.meanDayCars[0]?.meanCars) || 0}</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center">
                  Tempo de estadia médio:{" "}
                  <b>
                    {Math.round(
                      data?.meanOccuppationTime[0]?.meanTime / 3600000
                    ) || 0}
                    h
                  </b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" align="center">
                  Usuários recorrentes:{" "}
                  <b>
                    {console.log(data?.meanActivity)}
                    {procMeanActivity}
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Informações Park Lockyt
            </Typography>
            <Divider style={{ marginBottom: 10 }} />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Typography variant="body1" align="center">
                  Veículos: <b>{data?.dataCount?.inLog || 0}</b>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {data?.dataCount?.sensorPort0?.inData ||
        data?.dataCount?.sensorPort1?.inData ||
        data?.dataCount?.sensorPort0?.outData ||
        data?.dataCount?.sensorPort1?.outData ? (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography variant="body1" align="center">
                    Fluxo de entrada:{" "}
                    <b>{data?.dataCount?.sensorPort0?.inData || 0}</b> |{" "}
                    <b>{data?.dataCount?.sensorPort1?.inData || 0}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center">
                    Fluxo de saída:{" "}
                    <b>{data?.dataCount?.sensorPort0?.outData || 0}</b> |{" "}
                    <b>{data?.dataCount?.sensorPort1?.outData || 0}</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" align="center">
                    Estimativa de veículos estacionados:{" "}
                    {data?.dataCount?.sensorPort0?.inData +
                      parkData?.carsOffset -
                      data?.dataCount?.sensorPort0?.outData >=
                    0 ? (
                      <b>
                        {data?.dataCount?.sensorPort0?.inData +
                          parkData?.carsOffset -
                          data?.dataCount?.sensorPort0?.outData}
                      </b>
                    ) : !data || !parkData ? (
                      <b>0</b>
                    ) : (
                      <b style={{ color: "red" }}>0</b>
                    )}{" "}
                    |{" "}
                    {data?.dataCount?.sensorPort1?.inData +
                      parkData?.carsOffset -
                      data?.dataCount?.sensorPort1?.outData >=
                    0 ? (
                      <b>
                        {data?.dataCount?.sensorPort1?.inData +
                          parkData?.carsOffset -
                          data?.dataCount?.sensorPort1?.outData}
                      </b>
                    ) : !data || !parkData ? (
                      <b>0</b>
                    ) : (
                      <b style={{ color: "red" }}>0</b>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Sensor dos equipamentos ► Loop | Cancela
            </Typography>
            <Divider style={{ marginBottom: 10 }} />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              {Object.keys(data?.boardAmount?.in || {}).length > 0 && (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Entrada
                      </Typography>
                    </Grid>
                    {Object.keys(data?.boardAmount?.in || {}).map((e, i) => (
                      <Grid item xs key={i}>
                        <Typography
                          variant="body1"
                          align={isMobile ? "center" : "left"}
                        >
                          {e} ► <b>{data?.boardAmount?.in[e].port0}</b>|
                          <b>{data?.boardAmount?.in[e].port1}</b>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
              {Object.keys(data?.boardAmount?.in_out || {}).length > 0 && (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Mista
                      </Typography>
                    </Grid>
                    {Object.keys(data?.boardAmount?.in_out || {}).map(
                      (e, i) => (
                        <Grid item xs md={12} key={i}>
                          <Typography
                            variant="body1"
                            align={isMobile ? "center" : "left"}
                          >
                            {e} ► <b>{data?.boardAmount?.in_out[e].port0}</b>|
                            <b>{data?.boardAmount?.in_out[e].port1}</b>
                          </Typography>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
              )}
              {Object.keys(data?.boardAmount?.out || {}).length > 0 && (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Saída
                      </Typography>
                    </Grid>
                    {Object.keys(data?.boardAmount?.out || {}).map((e, i) => (
                      <Grid item xs md={12} key={i}>
                        <Typography
                          variant="body1"
                          align={isMobile ? "center" : "left"}
                        >
                          {e} ► <b>{data?.boardAmount?.out[e].port0}</b>|
                          <b>{data?.boardAmount?.out[e].port1}</b>
                        </Typography>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {/* <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="body1" align="center">
              <b>Entrada </b>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="body1" align="center">
              <b>Saída </b>
            </Typography>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              Tempos
            </Typography>
            <Divider style={{ marginBottom: 10 }} />
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={1}
            >
              {data?.times?.in[0] && (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Entrada
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" align="center">
                        Médio:{" "}
                        <b>
                          {data?.times?.in[0]?.meanTime
                            ? (data?.times?.in[0]?.meanTime / 1000).toFixed(2)
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid>
                    {/*  <Grid item xs>
                      <Typography variant="body1" align="center">
                        Mínimo:{" "}
                        <b>
                          {data?.times?.in[0]?.minTime
                            ? (data?.times?.in[0]?.minTime / 1000).toFixed(2)
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid> */}
                    <Grid item xs>
                      <Typography variant="body1" align="center">
                        Máximo:{" "}
                        <b>
                          {data?.times?.in[0]?.maxTime
                            ? (data?.times?.in[0]?.maxTime / 1000).toFixed(2)
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {data?.times?.in_out[0] && (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Mista
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" align="center">
                        Médio:{" "}
                        <b>
                          {data?.times?.in_out[0]?.meanTime
                            ? (data?.times?.in_out[0]?.meanTime / 1000).toFixed(
                                2
                              )
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid>
                    {/* <Grid item xs>
                      <Typography variant="body1" align="center">
                        Mínimo:{" "}
                        <b>
                          {data?.times?.in_out[0]?.minTime
                            ? (data?.times?.in_out[0]?.minTime / 1000).toFixed(
                                2
                              )
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid> */}
                    <Grid item xs>
                      <Typography variant="body1" align="center">
                        Máximo:{" "}
                        <b>
                          {data?.times?.in_out[0]?.maxTime
                            ? (data?.times?.in_out[0]?.maxTime / 1000).toFixed(
                                2
                              )
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {data?.times?.out[0] && (
                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="space-around"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: "bold" }}
                      >
                        Saída
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="body1" align="center">
                        Médio:{" "}
                        <b>
                          {data?.times?.out[0]?.meanTime
                            ? (data?.times?.out[0]?.meanTime / 1000).toFixed(2)
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid>
                    {/*  <Grid item xs>
                      <Typography variant="body1" align="center">
                        Mínimo:{" "}
                        <b>
                          {data?.times?.out[0]?.minTime
                            ? (data?.times?.out[0]?.minTime / 1000).toFixed(2)
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid> */}
                    <Grid item xs>
                      <Typography variant="body1" align="center">
                        Máximo:{" "}
                        <b>
                          {data?.times?.out[0]?.maxTime
                            ? (data?.times?.out[0]?.maxTime / 1000).toFixed(2)
                            : "0"}
                          s
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        {Object.keys(pieChartData || {}).length > 0 &&
          data &&
          data?.dataCount?.sensorPort1?.inData > 0 && (
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Pie
                  data={pieChartData}
                  options={{
                    title: {
                      text: "Ocupação do estacionamento",
                      display: true,
                    },
                    legend: { position: "bottom" },
                  }}
                />
              </Paper>
            </Grid>
          )}
        {Object.keys(lineChartData || {}).length > 0 && lineChartData?.sum > 0 && (
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Line
                data={lineChartData}
                options={{
                  title: {
                    text: "Fluxo diário",
                    display: true,
                  },
                  legend: { position: "bottom" },
                }}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body2" align="center">
            Esta página se recarrega de forma automática a cada 5 min
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Payment;
