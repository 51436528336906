import React, { useState, useEffect } from "react";

import axios from "config/axios";
import {
  Grid,
  Paper,
  Typography,
  IconButton,
  LinearProgress,
  Button,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import Select from "react-select";

//Icons
import InfoIcon from "@material-ui/icons/Info";
import ErrorIcon from "@material-ui/icons/Error";
import Delete from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Clear from "@material-ui/icons/Clear";
import Search from "@material-ui/icons/Search";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog.js";

//custom functions
import isEmail from "functions/isEmail";

import styles, { selectStyles } from "./styles.js";
moment.locale("pt-br");

const baseMonths = [
  { value: 0, label: "Janeiro" },
  { value: 1, label: "Fevereiro" },
  { value: 2, label: "Março" },
  { value: 3, label: "Abril" },
  { value: 4, label: "Maio" },
  { value: 5, label: "Junho" },
  { value: 6, label: "Julho" },
  { value: 7, label: "Agosto" },
  { value: 8, label: "Setembro" },
  { value: 9, label: "Outubro" },
  { value: 10, label: "Novembro" },
  { value: 11, label: "Dezembro" },
];

function MonthlyUser() {
  const classes = styles();
  const [data, setData] = useState([]),
    [addPrice, setAddPrice] = useState(""),
    [prices, setPrices] = useState([]),
    [alert, setAlert] = useState({ alert: false, msg: "" }),
    [delMothly, setDelMonthly] = useState(),
    [loading, setLoading] = useState(false),
    [addEmail, setAddEmail] = useState(""),
    [editMonthly, setEditMonthly] = useState(),
    [newPrice, setNewPrice] = useState(""),
    [filterName, setFilterName] = useState(""),
    [filterCellphone, setFilterCellphone] = useState(""),
    [months] = useState(() => baseMonths),
    [selectedMonths, setSelectedMonths] = useState([]),
    [alertWarning, setAlertWarning] = useState({ alert: false, msg: "" }),
    [alertSuccess, setAlertSuccess] = useState({ alert: false, msg: "" });
  let isMobile = window.innerWidth <= 900;

  const [, updateState] = useState();

  const getData = async (clear) => {
    setLoading(true);
    let route = `/park/monthly/users/${filterName || 0}/${
      filterCellphone.replace(/\D/g, "") || 0
    }`;
    if (clear) {
      route = `/park/monthly/users/0/0`;
    }
    axios
      .get(route)
      .then(({ data }) => {
        let result = [];
        data.forEach((e) => {
          if (e.user) {
            result.push(e);
          }
        });
        setData(result);
      })
      .catch((error) => {
        console.log(error);
        switch (error?.response?.data?.status) {
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao carregar mensalistas",
            });
        }
      });
    axios
      .get("/park/monthly/prices")
      .then(({ data }) => {
        setLoading(false);
        let result = [];
        data.forEach((e) => {
          result.push({
            value: e.price,
            label: new Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(e.price || 0),
            _id: e._id,
          });
        });
        setPrices(result);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        switch (error?.response?.data?.status) {
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao carregar valores",
            });
        }
      });
  };

  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDialog = async (e) => {
    setDelMonthly(e);
  };

  const removeMonthlyUser = async () => {
    setLoading(true);
    let monthly = delMothly;
    setDelMonthly(null);
    axios
      .delete(`/park/remove/monthly/user/${monthly._id}`)
      .then(() => {
        getData();
        setLoading(false);
        setAlertSuccess({ alert: true, msg: "Removido com sucesso" });
      })
      .catch((error) => {
        setLoading(false);
        switch (error?.response?.data?.status) {
          default:
            return setAlert({
              alert: true,
              msg: "Problema ao carregar mensalistas",
            });
        }
      });
  };

  const addMonthlyUser = async () => {
    if (!addEmail) {
      return setAlert({
        alert: true,
        msg: "Preencha o campo com o email do usuário",
      });
    }
    if (!isEmail(addEmail)) {
      return setAlert({
        alert: true,
        msg: "O email digitado é inválido",
      });
    }
    if (!addPrice) {
      return setAlert({
        alert: true,
        msg: "Selecione o valor a ser cobrado",
      });
    }
    setLoading((oldState) => !oldState);
    axios
      .post("/park/add/monthly/user", {
        userEmail: addEmail,
        priceId: addPrice,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlert({ alert: false, msg: "" });
        setAddEmail("");
        setAlertSuccess({ alert: true, msg: "Cadastrado com sucesso" });
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        setAlertSuccess({ alert: false, msg: "" });
        console.log(error.response.data);
        switch (error?.response?.data?.status) {
          case 2:
            setAlert({ alert: true, msg: "Usuário não encontrado" });
            break;
          case 3:
            setAlert({ alert: true, msg: "Valor não encontrado" });
            break;
          case 4:
            setAlert({ alert: true, msg: "Mensalista já cadastrado" });
            break;
          case 5:
            setAlert({
              alert: true,
              msg: "Problema ao realizar pagamento do mensalista",
            });
            break;
          case 6:
            setAlert({
              alert: true,
              msg: "Usuário não possui meio de pagamento",
            });
            break;
          default:
            setAlert({ alert: true, msg: "Problema ao adicionar mensalista" });
            break;
        }
      });
  };

  /*  const updateMonthlyUserPrice = async () => {
    if (!newPrice) {
      return setAlert({
        alert: true,
        msg: "Selecione o valor",
      });
    }
    setLoading((oldState) => !oldState);
    axios
      .put("/park/monthly/user/price", {
        monthlyId: editMonthly.monthly._id,
        price: newPrice,
      })
      .then(() => {
        setLoading((oldState) => !oldState);
        setAlert({ alert: false, msg: "" });
        setAlertSuccess({ alert: true, msg: "Atualizado com sucesso" });
        setEditMonthly(null);
        setNewPrice(null);
        getData();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        setAlertSuccess({ alert: false, msg: "" });
        console.log(error);
        switch (error?.response?.data?.status) {
          case 1:
            setAlert({
              alert: true,
              msg: "Valor não encontrado",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao atualizar valor",
            });
            break;
        }
      });
  }; */

  const maskCellphone = async (e) => {
    let v = e.target.value;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    if (v.length > 11) {
      return;
    }
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    setFilterCellphone(v);
  };

  const clearFilter = async () => {
    setFilterName("");
    setFilterCellphone("");
    getData(true);
  };

  const procEditMonthly = (e, i) => {
    let monthsInUse = [];
    months.forEach((data, index) => {
      if (e.months.indexOf(data.value) >= 0) {
        monthsInUse.push({
          value: e.months[index],
          label: baseMonths[index].label,
        });
      }
    });
    setSelectedMonths(monthsInUse);
    setEditMonthly({ monthly: e, index: i });
  };

  const updateMonthlyUser = async () => {
    if (selectedMonths.length === 0) {
      return setAlert({ alert: true, msg: "Selecione pelo menos 1 mês" });
    }
    let values = [];
    selectedMonths.forEach((e) => {
      values.push(e.value);
    });
    axios
      .put("/park/monthly/user", {
        monthlyId: editMonthly?.monthly?._id,
        months: values,
      })
      .then((val) => {
        setLoading((oldState) => !oldState);
        setAlert({ alert: false, msg: "" });
        setAlertSuccess({ alert: true, msg: "Atualizado com sucesso" });
        setEditMonthly(null);
        getData();
      })
      .catch((error) => {
        setAlertSuccess({ alert: false, msg: "" });
        switch (error?.response?.data?.status) {
          case 1:
            break;
          default:
            setAlert({ alert: true, msg: "Problema ao atualizar mensalista" });
            break;
        }
      });
  };

  return (
    <div>
      <ConfirmDialog
        openDialog={delMothly ? true : false}
        title={`Remover mensalista ${delMothly?.user?.name}`}
        body1="Confirmar remoção de mensalista?"
        handleYes={removeMonthlyUser}
        handleClose={() => setDelMonthly(null)}
      />
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() => setAlert({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess.msg}
        open={alertSuccess.alert}
        closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="tc"
        color="warning"
        icon={InfoIcon}
        message={alertWarning.msg}
        open={alertWarning.alert}
        closeNotification={() => setAlertWarning({ alert: false, msg: "" })}
        close
      />
      {loading && <LinearProgress />}
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
        spacing={isMobile ? 4 : 2}
      >
        <Grid item>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h5">Adicionar mensalista</Typography>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label="Email"
                  value={addEmail}
                  onChange={(e) => setAddEmail(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <Select
                  options={prices}
                  onChange={(e) => setAddPrice(e._id)}
                  //value={defaultOption}
                  placeholder="Valor a cobrar"
                  styles={selectStyles}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  color="secondary"
                  onClick={() => addMonthlyUser()}
                  disabled={loading}
                >
                  Adicionar mensalista
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <Paper style={{ padding: 16 }}>
            <Typography variant="h5">Filtrar mensalista</Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <TextField
                  style={{ marginBottom: 20 }}
                  label="Nome"
                  value={filterName}
                  onChange={(e) => {
                    setFilterName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  style={{ marginBottom: 20 }}
                  label="Telefone"
                  value={filterCellphone}
                  onChange={maskCellphone}
                />
              </Grid>
              <Grid item>
                <Button
                  onClick={clearFilter}
                  startIcon={<Clear />}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                >
                  Limpar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  startIcon={<Search />}
                  onClick={() => getData()}
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {data.length === 0 && (
          <Grid item>
            <Paper style={{ padding: 16 }}>
              <Typography variant="h5" align="center">
                Sem registros
              </Typography>
            </Paper>
          </Grid>
        )}
        {data.map((e, index) =>
          index === editMonthly?.index ? (
            <Grid item key={index}>
              <Paper style={{ padding: 16 }}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={3}
                >
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setEditMonthly(null);
                        setNewPrice("");
                      }}
                      disabled={loading}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" className={classes.gridText}>
                      Cliente: <b>{e?.user?.name}</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 4}>
                    <Select
                      options={months}
                      isMulti
                      onChange={(e) => setSelectedMonths(e)}
                      value={selectedMonths}
                      placeholder="Meses"
                      styles={selectStyles}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => updateMonthlyUser()}
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                    >
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : (
            <Grid item key={index}>
              <Paper style={{ padding: 16 }}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="body1" className={classes.gridText}>
                      {data.length - index} - Cliente: <b>{e?.user?.name}</b> -{" "}
                      {new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(e?.monthlyPrice?.price || 0)}
                    </Typography>
                  </Grid>
                  {new Date() < new Date(e.maxDate) ? (
                    <Grid item>
                      <Typography variant="body1">
                        Vence em:{" "}
                        <b>{moment(e.maxDate).format("DD/MM/YYYY")}</b>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Typography variant="body1">
                        Vence em:{" "}
                        <b style={{ color: "red" }}>
                          {moment(e.maxDate).format("DD/MM/YYYY")}
                        </b>
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <IconButton onClick={() => procEditMonthly(e, index)}>
                      <Edit />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => confirmDialog(e)}
                      disabled={loading}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
}

export default MonthlyUser;
